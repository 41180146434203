<template>
  <div class="content_body TimeCard" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="时效卡名称">
              <el-input @clear="handleSearch" v-model="name" placeholder="输入时效卡名称或别名" clearable></el-input>
            </el-form-item>
            <el-form-item label="时效卡分类">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="timeCardCategoryID" placeholder="时效卡分类" clearable filterable>
                <el-option v-for="item in category" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="isAllowSell" placeholder="请选择是否上架" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button v-if="isTimeCardNew" type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
          <el-button type="primary" plain size="small" @click="showAddDialog" v-if="false" v-prevent-click>导入</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="时效卡名称"></el-table-column>
        <el-table-column prop="Alias" label="时效卡别名"></el-table-column>
        <el-table-column prop="TargetWeight" label="目标减重"></el-table-column>
        <el-table-column prop="TimeCardCategoryName" label="时效卡分类"></el-table-column>
        <el-table-column prop="Price" label="销售价格">
          <template slot-scope="scope">￥{{scope.row.Price | NumFormat}}</template>
        </el-table-column>
        <el-table-column prop="ValidDayName" label="卡有效期"></el-table-column>
        <el-table-column prop="IsAllowLargess" label="是否可赠送" :formatter="formatLargessStatus"></el-table-column>
        <el-table-column prop="IsAllowSell" label="是否上架" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button v-if="isTimeCardEdit" type="primary" size="small" @click="showEditDialog(scope.row,'edit')" v-prevent-click>编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 dis_flex flex_x_between">
        <div>
          <el-dropdown trigger="click" @command="dropdownClick" v-if="false">
            <el-button type="primary" size="small" v-prevent-click>
              批量设置
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">角色分配</el-dropdown-item>
              <el-dropdown-item command="2">权限范围</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="text_right">
          <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
        </div>
      </div>
    </div>
    <!--新增，编辑弹窗-->
    <el-dialog :title="isAdd?'新增时效卡':  '编辑时效卡' " :visible.sync="dialogVisible" :close-on-click-modal="false" width="1000px">
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" size="small" label-width="130px">
              <el-col :span="12">
                <el-form-item label="时效卡名称" prop="Name">
                  <el-input v-model="ruleForm.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时效卡别名">
                  <el-input v-model="ruleForm.Alias"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时效卡分类" prop="TimeCardCategoryID">
                  <el-select v-model="ruleForm.TimeCardCategoryID" filterable clearable placeholder="请选择时效卡分类" style="position:absolute">
                    <el-option v-for="item in category" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="消耗周期">
                  <span slot="label">
                    消耗周期
                    <el-popover placement="top-start" width="200" trigger="hover">
                      <p>限定时效卡在有效期内使用的周期，不填写表示不限制。</p>
                      <p>比如：1周只能使用2次，则设置“消耗周期”为7，“周期次数”为2。</p>
                      <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </span>
                  <el-input v-model="ruleForm.ConsumeCycle" type="number" :min="0" v-enter-number>
                    <template slot="append">天</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格" prop="Price">
                  <el-input v-model="ruleForm.Price" type="number" :min="0" v-enter-number2>
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="周期次数">
                  <span slot="label">
                    周期次数
                    <el-popover placement="top-start" width="200" trigger="hover">
                      <p>限定时效卡在一个周期内使用次数，不填写表示不限制。</p>
                      <p>比如：1周只能使用2次，则设置“消耗周期”为7，“周期次数”为2。</p>
                      <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </span>
                  <el-input v-model="ruleForm.CycleLimitAmount" type="number" :min="0" v-enter-number>
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="消耗提成次数" prop="PerformanceAmount">
                  <span slot="label">
                    消耗提成次数
                    <el-popover placement="top-start" width="200" trigger="hover" style="position: absolute">
                      <p>用于时效卡门店确认收入和确认员工消耗业绩。</p>
                      <p>比如：时效卡金额为1000，消耗提成次数为10，则每次使用时效卡耗做项目，则1~10次时，门店的确认收入为100，员工业绩为100，11次以后（包含第11次），门店确认收入为0，员工业绩为0。</p>
                      <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </span>
                  <el-input v-model="ruleForm.PerformanceAmount" type="number" v-enter-number>
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售卖日期" prop="saleDate">
                  <el-date-picker clearable v-model="ruleForm.saleDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" unlink-panels></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="目标减重" prop="TargetWeight">
                  <el-input v-model="ruleForm.TargetWeight" type="number" :min="0" v-enter-number2>
                    <template slot="append">斤</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架" prop="IsAllowSell">
                  <el-radio-group v-model="ruleForm.IsAllowSell">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可以赠送" prop="IsAllowLargess">
                  <el-radio-group v-model="ruleForm.IsAllowLargess">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="卡有效期" prop="ValidType">
                  <div>
                    <el-radio-group v-model="ruleForm.ValidType">
                      <el-radio :label="3">永久有效</el-radio>
                      <el-radio :label="1">销售后生成</el-radio>
                      <el-radio :label="2">消耗后生成</el-radio>
                    </el-radio-group>
                    <span v-if="ruleForm.ValidType !=3">
                      <el-input class="radio_input" v-model="ruleForm.ValidDay" type="number" v-enter-number placeholder="天数" v-enterNumber2 min="0">
                        <template slot="append">天</template>
                      </el-input>
                    </span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息">
                  <el-input type="textarea" :rows="4" placeholder="请输入备注信息" v-model="ruleForm.Remark"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="详细信息" name="5">
            <div class="dis_flex flex_dir_row">
              <goodsDetail ref="goodsDetail" :Memo="ruleForm.Memo" :ImageList="ruleForm.ImageList" :OriginalText="ruleForm.OriginalText" :Price="ruleForm.Price" :Name="ruleForm.Name" :isShowOriginal="false" GoodsType="时效卡" @Memo_change="Memo_change" @ImageListChange="ImageListChange">
                <el-row slot="info">
                  <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                  <el-col :span="24" class="back_f8 pad_10">
                    <el-col :span="24" class="martp_5">有效期:
                      <span v-if="ruleForm.ValidType == 1">购买后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 2">第一次使用后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 3">永久有效</span>
                    </el-col>
                    <el-col :span="24" class="martp_5">使用周期: {{ruleForm.ConsumeCycle}} 天内，可使用 {{ruleForm.PerformanceAmount}} 次</el-col>
                    <el-col :span="24" class="martp_5">适用项目: 适用共 {{TimeCardProject.length}} 个项目 <span style="color:#FF8D66">查看详情</span></el-col>
                    <el-col :span="24" class="martp_5">适用门店: 适用共 {{ruleForm.ApplyNum}} 家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                  </el-col>
                </el-row>
              </goodsDetail>
            </div>

          </el-tab-pane>
          <el-tab-pane label="适用项目" name="4">
            <el-row :gutter="20" class="marbm_10">
              <el-col :span="6">
                <el-input placeholder="输入项目名称搜索" size="small" v-model="filterProjectName" clearable></el-input>
              </el-col>
              <el-col :span="12">
                <el-button type="primary" size="small" @click="addProjectDialog(1)">配置适用项目</el-button>
              </el-col>
            </el-row>
            <el-table size="small" :data="TimeCardProject.filter(data => !filterProjectName || data.Name.toLowerCase().includes(filterProjectName.toLowerCase()))" max-height="400">
              <el-table-column prop="Name" label="项目名称" sortable></el-table-column>
              <el-table-column prop="ProjectCategoryName" label="项目分类名称" sortable></el-table-column>
              <el-table-column prop="Price" label="项目销售价格" sortable></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="销售范围" name="2">
            <div slot="label">
              销售范围
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需勾选父节点。</p>
                <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
                <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </div>
            <el-scrollbar class="el-scrollbar_height">
              <el-tree ref="treeSale" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="saleEntityList" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysSale" :default-expanded-keys="defaultExpandedKeysSale" :props="defaultProps"></el-tree>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="消耗范围" name="3">
            <div slot="label">
              消耗范围
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需勾选父节点。</p>
                <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
                <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
              </el-popover>
            </div>
            <el-scrollbar class="el-scrollbar_height">
              <el-tree ref="treeConsume" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="consumeEntityList" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysConsume" :default-expanded-keys="defaultExpandedKeysConsume" :props="defaultProps"></el-tree>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="addSubmit" :loading="modalLoading" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
    <!-- 查看弹出层 -->
    <el-dialog title="时效卡详情" :visible.sync="showDialogVisible" width="1000px">
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="1">
            <el-form class="timeShow" size="small" label-width="130px">
              <el-col :span="12">
                <el-form-item label="时效卡名称：">
                  <span>{{ruleForm.Name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时效卡别名：">
                  <span>{{ruleForm.Alias}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时效卡分类：">
                  <span>{{TimeCardCategory}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="消耗周期">
                  <span slot="label">
                    消耗周期
                    <el-popover placement="top-start" width="200" trigger="hover">
                      <p>限定时效卡在有效期内使用的周期，不填写表示不限制。</p>
                      <p>比如：1周只能使用2次，则设置“消耗周期”为7，“周期次数”为2。</p>
                      <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </span>
                  <span>{{ruleForm.ConsumeCycle}}天</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格：">
                  <span>{{ruleForm.Price}}元</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="周期次数">
                  <span slot="label">
                    周期次数
                    <el-popover placement="top-start" width="200" trigger="hover">
                      <p>限定时效卡在一个周期内使用次数，不填写表示不限制。</p>
                      <p>比如：1周只能使用2次，则设置“消耗周期”为7，“周期次数”为2。</p>
                      <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </span>
                  <span>{{ruleForm.CycleLimitAmount}}次</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="消耗提成次数">
                  <span slot="label">
                    消耗提成次数
                    <el-popover placement="top-start" width="200" trigger="hover" style="position: absolute">
                      <p>用于时效卡门店确认收入和确认员工消耗业绩。</p>
                      <p>比如：时效卡金额为1000，消耗提成次数为10，则每次使用时效卡耗做项目，则1~10次时，门店的确认收入为100，员工业绩为100，11次以后（包含第11次），门店确认收入为0，员工业绩为0。</p>
                      <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </span>
                  <span>{{ruleForm.PerformanceAmount}}次</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售卖日期：">
                  <span>{{saleDate}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架：">
                  <span v-if="ruleForm.IsAllowSell">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可以赠送：">
                  <span v-if="ruleForm.IsAllowLargess">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="卡有效期：">
                  <span v-if="ruleForm.ValidType==3">永久有效</span>
                  <span v-else-if="ruleForm.ValidType==1">销售后生成{{ruleForm.ValidDay}}天</span>
                  <span v-else>消耗后生成{{ruleForm.ValidDay}}天</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="目标减重：">
                  <span>{{ruleForm.TargetWeight}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息：">
                  <span>{{ruleForm.Remark}}</span>
                </el-form-item>
              </el-col>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="详细信息" name="2">
            <div class="dis_flex flex_x_center">
              <showGoodsDetail ref="ShowGoodsDetail" :Memo="ruleForm.Memo" :ImageList="ruleForm.ImageList" :OriginalText="ruleForm.OriginalText" :Price="ruleForm.Price" :Name="ruleForm.Name" :isShowOriginal="false" GoodsType="时效卡">
                <el-row slot="info">
                  <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                  <el-col :span="24" class="back_f8 pad_10">
                    <el-col :span="24" class="martp_5">有效期:
                      <span v-if="ruleForm.ValidType == 1">购买后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 2">第一次使用后 {{ruleForm.ValidDay}} 天内有效</span>
                      <span v-if="ruleForm.ValidType == 3">永久有效</span>
                    </el-col>
                    <el-col :span="24" class="martp_5">使用周期: {{ruleForm.ConsumeCycle}} 天内，可使用 {{ruleForm.PerformanceAmount}} 次</el-col>
                    <el-col :span="24" class="martp_5">适用项目: 适用共 {{TimeCardProject.length}} 个项目 <span style="color:#FF8D66">查看详情</span></el-col>
                    <el-col :span="24" class="martp_5">适用门店: 适用共 {{ruleForm.ApplyNum}} 家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                  </el-col>
                </el-row>
              </showGoodsDetail>
            </div>

          </el-tab-pane>
          <el-tab-pane label="适用项目" name="4">
            <el-row :gutter="20" class="marbm_10">
              <el-col :span="6">
                <el-input placeholder="输入项目名称搜索" size="small" v-model="filterProjectName" clearable></el-input>
              </el-col>
              <el-col :span="12">
              </el-col>
            </el-row>
            <el-table size="small" :data="TimeCardProject.filter(data => !filterProjectName || data.Name.toLowerCase().includes(filterProjectName.toLowerCase()))" max-height="400">
              <el-table-column prop="Name" label="项目名称" sortable></el-table-column>
              <el-table-column prop="ProjectCategoryName" label="项目分类名称" sortable></el-table-column>
              <el-table-column prop="Price" label="项目销售价格" sortable></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <!--适用项目-->
    <el-dialog title="配置时效卡适用项目" :visible.sync="dialogProject" width="900px">
      <div>
        <el-row>
          <el-col :span="8">
            <el-input v-model="projectName" placeholder="输入关键字进行搜索" clearable size="small"></el-input>
            <el-scrollbar class="el-scrollbar_height">
              <el-tree ref="tree" :expand-on-click-node="false" :check-on-click-node="true" :data="categoryProject" show-checkbox accordion node-key="PID" :default-checked-keys="defaultCheckedKeys" :props="{children: 'Child',label: 'Name'}" :filter-node-method="filterNode" @check="changeCheckedData">
                <span slot-scope="{ data }">
                  <span>{{ data.Name }}</span>
                  <el-tag class="marlt_5" type="info" size="mini" v-if="!data.IsProject">分类</el-tag>
                </span>
              </el-tree>
            </el-scrollbar>
          </el-col>
          <el-col :span="16">
            <el-table size="small" :data="timeCardProject.filter(data => !projectName || data.Name.toLowerCase().includes(projectName.toLowerCase()))" max-height="500px">
              <el-table-column prop="Name" label="项目名称"></el-table-column>
              <el-table-column prop="ProjectCategoryName" label="项目分类名称"></el-table-column>
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="removetProject(scope.row,scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogProject = false" v-prevent-click>取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect" v-prevent-click>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var Enumerable = require("linq");
import APICategory from "@/api/iBeauty/Basic/timeCardCategory";
import APIGeneralCard from "@/api/iBeauty/Goods/generalCard";
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/Goods/timeCard";
import permission from "@/components/js/permission.js";
import goodsDetail from "../Goods/Components/GoodsDetail.vue";
import utils from "@/components/js/utils.js";
import showGoodsDetail from "../Goods/Components/ShowGoodsDetail.vue";
export default {
  name: "TimeCard",
  /**  引入的组件  */
  components: {
    goodsDetail,
    showGoodsDetail,
  },
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      showDialogVisible: false, //查看弹出层
      dialogProject: false,
      isAdd: true,
      activeName: "1",
      name: "",
      timeCardCategoryID: null, // 时效卡分类ID
      isAllowSell: true, // 是否上架
      timeCardId: "",
      category: [], //分类
      tableData: [],
      entityList: [],
      saleEntityList: [], //销售范围
      consumeEntityList: [], //消耗范围
      defaultCheckedKeysSale: [],
      defaultExpandedKeysSale: [1],
      defaultCheckedKeysConsume: [],
      defaultExpandedKeysConsume: [1],
      projectName: "",
      categoryProject: [], //分类及项目
      timeCardProject: [], //选中适用项目
      defaultCheckedKeys: [], //回显
      filterProjectName: "", //项目过滤
      TimeCardProject: [], //适用项目数组
      ruleForm: {
        Name: "", //时效卡名称
        Alias: "", //时效卡别名
        TimeCardCategoryID: "", //时效卡类别ID
        Price: "", //售价
        ConsumeCycle: 0, //消耗周期。不限制则填0
        CycleLimitAmount: 0, //周期次数。不限制则填0
        PerformanceAmount: "", //消耗提成次数
        IsAllowSell: true, //是否上架。值为true/false
        IsAllowLargess: true, //是否可赠送。值为true/false
        SaleStartDate: "", //售卖开始时间
        SaleEndingDate: "", //售卖结束时间
        saleDate: "",
        ValidType: 3, //有效期类型（1：销售后生成、2：消耗后生成、3：永久有效）
        ValidDay: "", //有效期天数（单位：天）
        TimeCardProject: [], //适用项目数组
        TimeCardSaleEntity: [], //时效卡销售范围
        TimeCardConsumeEntity: [], //时效卡消耗范围
        Remark: "",
        Memo: "",
        ImageList: [],
        TargetWeight:""
      },
      rules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Alias: [{ required: true, message: "请输入别名", trigger: "blur" }],
        TimeCardCategoryID: [
          { required: true, message: "请选择类别", trigger: "change" },
        ],
        Price: [{ required: true, message: "请输入售价", trigger: "blur" }],
        TargetWeight: [{ required: true, message: "请输入目标减重", trigger: "blur" }],
        ConsumeCycle: [
          { required: true, message: "请输入消耗周期", trigger: "blur" },
        ],
        CycleLimitAmount: [
          { required: true, message: "请输入周期次数", trigger: "blur" },
        ],
        PerformanceAmount: [
          { required: true, message: "请输入消耗提成次数", trigger: "blur" },
        ],
        IsAllowSell: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
        IsAllowLargess: [
          { required: true, message: "请选择是否可赠送", trigger: "change" },
        ],
        saleDate: [
          { required: true, message: "请选择售卖时间", trigger: "change" },
        ],
        ValidType: [
          { required: true, message: "请选择有效期类型", trigger: "change" },
        ],
        ValidDay: [
          { required: true, message: "请输入有效期天数", trigger: "blur" },
        ],
        TimeCardProject: [
          {
            type: "array",
            required: true,
            message: "请选择适用项目",
            trigger: "change",
          },
        ],
        TimeCardSaleEntity: [
          {
            type: "array",
            required: true,
            message: "请选择时效卡销售范围",
            trigger: "change",
          },
        ],
        TimeCardConsumeEntity: [
          {
            type: "array",
            required: true,
            message: "请选择时效卡消耗范围",
            trigger: "change",
          },
        ],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      isTimeCardEdit: "", //编辑权限
      isTimeCardNew: "", //新增权限
    };
  },
  computed: {
    TimeCardCategory: function () {
      if (!this.ruleForm.TimeCardCategoryID) return "";
      const value = this.ruleForm.TimeCardCategoryID.toString();
      const item = this.category.find((item) => item.ID == value);
      return item.Name;
    },
    saleDate: function () {
      return (
        this.ruleForm.SaleStartDate +
        " " +
        "至" +
        " " +
        this.ruleForm.SaleEndingDate
      );
    },
  },
  watch: {
    projectName(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    // //状态显示转换
    formatLargessStatus: function (row) {
      return row.IsAllowLargess ? "是" : "否";
    },
    formatStatus: function (row) {
      return row.IsAllowSell ? "是" : "否";
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 分类数据
    categoryData: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: true,
      };
      APICategory.getTimeCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.category = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        TimeCardCategoryID: that.timeCardCategoryID,
        IsAllowSell: that.isAllowSell,
        PageNum: that.paginations.page,
      };
      API.getTimeCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    // 销售消耗范围
    entityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
        Active: true,
      };
      APIEntity.getEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 时效卡销售范围
    saleEntityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.timeCardId,
      };
      API.getTimeCardSaleEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysSale = res.Data;
            that.defaultExpandedKeysSale = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 时效卡消耗范围
    consumeEntityData: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.timeCardId,
      };
      API.getTimeCardConsumeEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysConsume = res.Data;
            that.defaultExpandedKeysConsume = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取时效卡适用项目
    timeCardProjectData: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.timeCardId,
      };
      API.getTimeCardProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.Data.forEach(function (item) {
              item.ID = item.ProjectID;
              item.PID = "1" + item.ProjectID;
            });
            that.timeCardProject = res.Data;
            that.TimeCardProject = Object.assign([], res.Data);
            that.ruleForm.TimeCardProject = Object.assign([], res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.activeName = "1";
      that.ruleForm = {
        Name: "",
        Alias: "",
        TimeCardCategoryID: "",
        Price: "",
        ConsumeCycle: "",
        CycleLimitAmount: "",
        PerformanceAmount: "",
        IsAllowSell: true,
        IsAllowLargess: false,
        SaleStartDate: "",
        SaleEndingDate: "",
        saleDate: "",
        ValidType: 3,
        ValidDay: "",
        TimeCardProject: [],
        TimeCardSaleEntity: [],
        TimeCardConsumeEntity: [],
        Memo: "",
        ImageList: [],
      };
      that.categoryProject = Object.assign([], that.categoryProject);
      that.TimeCardProject = [];
      that.timeCardProject = [];
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      that.defaultCheckedKeys = [];
      that.isAdd = true;

      that.categoryData();
      that.entityData();
      that.categoryProjectData();
      that.dialogVisible = true;
      that.saleEntityList = [];
      that.consumeEntityList = [];
      Object.assign(that.saleEntityList, that.entityList);
      Object.assign(that.consumeEntityList, that.entityList);
    },
    // 编辑,查看
    showEditDialog: function (row, type) {
      var that = this;
      that.isAdd = false;
      that.categoryData();
      that.entityData();
      that.categoryProjectData();
      that.dialogVisible = type == "edit" ? true : false;
      that.showDialogVisible = type == "show" ? true : false;
      that.timeCardId = row.ID;
      that.activeName = "1";
      that.ruleForm = Object.assign({}, row);
      that.$set(that.ruleForm, "saleDate", [
        new Date(row.SaleStartDate),
        new Date(row.SaleEndingDate),
      ]);
      that.saleEntityList = [];
      that.consumeEntityList = [];
      that.defaultCheckedKeysSale = [];
      that.defaultCheckedKeysConsume = [];
      Object.assign(that.saleEntityList, that.entityList);
      Object.assign(that.consumeEntityList, that.entityList);
      that.saleEntityData();
      that.consumeEntityData();
      that.timeCardProjectData();
      that.getTimeCardDetails(that.ruleForm.ID);
    },
    // 适用项目
    addProjectDialog: function () {
      var that = this;
      if (that.isAdd) {
        that.defaultCheckedKeys = [];
      } else {
        var defaultCheckedKeys = Enumerable.from(that.TimeCardProject)
          .select((val) => "1" + val.ID)
          .toArray();
        Object.assign(that.timeCardProject, that.TimeCardProject);
        if (defaultCheckedKeys.length == 0) {
          that.defaultCheckedKeys = [];
        } else {
          that.defaultCheckedKeys = defaultCheckedKeys;
        }
      }

      that.dialogProject = true;
    },
    // 分类及项目数据
    categoryProjectData: function () {
      var that = this;
      that.loading = true;
      APIGeneralCard.getFindCategoryAndProject()
        .then((res) => {
          if (res.StateCode == 200) {
            that.setRecursion(res.Data);
            that.categoryProject = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsProject) {
          data[i].PID = "1" + data[i].ID;
        } else {
          data[i].PID = "0" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 选择状态变化
    changeCheckedData: function (val, val1) {
      var that = this;
      var timeCardProject = Enumerable.from(val1.checkedNodes)
        .where(function (i) {
          return i.IsProject;
        })
        .select((val) => ({
          ID: val.ID,
          IsProject: val.IsProject,
          Name: val.Name,
          PID: val.PID,
          ParentID: val.ParentID,
          Price: val.Price,
          ProjectCategoryName: val.ProjectCategoryName,
        }))
        .toArray();
      that.timeCardProject = timeCardProject;
    },
    // 删除选中适用项目
    removetProject: function (row, index) {
      var that = this;
      that.timeCardProject.splice(index, 1);
      var defaultCheckedKeys = Enumerable.from(that.timeCardProject)
        .select((val) => val.PID)
        .toArray();
      that.$refs.tree.setCheckedKeys(defaultCheckedKeys);
      // that.defaultCheckedKeys = defaultCheckedKeys;
    },
    // 确认选中适用项目
    addProjectSelect: function () {
      var that = this;
      that.ruleForm.TimeCardProject = that.timeCardProject;
      that.TimeCardProject = that.timeCardProject;
      that.dialogProject = false;
    },
    // 提交
    addSubmit: function () {
      var that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.ruleForm.ValidType != 3 && that.ruleForm.ValidDay == "") {
            that.$message.error({
              message: "请输入有效期",
              duration: 2000,
            });
            return false;
          }
          if (that.ruleForm.TimeCardProject.length == 0) {
            that.$message.error({
              message: "请选择适用项目",
              duration: 2000,
            });
            return false;
          }
          that.modalLoading = true;
          let para = Object.assign({}, that.ruleForm);

          para.Memo = that.$refs["goodsDetail"].getMemo();
          para.ImageList = Enumerable.from(that.ruleForm.ImageList)
            .select((val) => ({ ImageUrl: val.url }))
            .toArray();
          para.SaleStartDate = para.saleDate[0];
          para.SaleEndingDate = para.saleDate[1];
          para.TimeCardProject = Enumerable.from(para.TimeCardProject)
            .select((val) => val.ID)
            .toArray();
          para.TimeCardSaleEntity = that.$refs.treeSale.getCheckedKeys();
          para.TimeCardConsumeEntity = that.$refs.treeConsume.getCheckedKeys();
          delete para.saleDate;
          if (that.isAdd) {
            API.createTimeCard(para)
              .then(function (res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    message: "新增成功",
                    duration: 2000,
                  });
                  that.search();
                  that.$refs["ruleForm"].resetFields();
                  that.dialogVisible = false;
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.modalLoading = false;
              });
          } else {
            API.updateTimeCard(para)
              .then(function (res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    message: "编辑成功",
                    duration: 2000,
                  });
                  that.search();
                  that.$refs["ruleForm"].resetFields();
                  that.dialogVisible = false;
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.modalLoading = false;
              });
          }
        }
      });
    },
    // 批量设置
    dropdownClick: function () {},

    /**    */
    Memo_change(val) {
      let that = this;
      that.ruleForm.Memo = val;
    },
    /**    */
    ImageListChange(val) {
      let that = this;
      that.ruleForm.ImageList = val;
    },
    /**  请求详情  */
    getTimeCardDetails(ID) {
      let that = this;
      let param = { ID: ID };
      API.getTimeCardDetails(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$set(that.ruleForm, "ImageList", []);
            that.$set(that.ruleForm, "Memo", "");
            that.$set(that.ruleForm, "ApplyNum", "");
            res.Data.ImageUrlList.forEach((item) => {
              utils.getCanvasBase64(item.ImageUrl).then((val) => {
                that.ruleForm.ImageList.push({ url: val });
              });
            });
            that.ruleForm.Memo = res.Data.Memo;
            that.ruleForm.ApplyNum = res.Data.ApplyNum;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isTimeCardEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-TimeCard-Update"
      );
      vm.isTimeCardNew = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-TimeCard-Create"
      );
    });
  },
  mounted() {
    var that = this;
    that.isTimeCardEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-TimeCard-Update"
    );
    that.isTimeCardNew = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-TimeCard-Create"
    );
    that.categoryData();
    that.handleSearch();
    that.entityData();
    that.categoryProjectData();
  },
};
</script>

<style  lang="scss">
.TimeCard {
  .radio_input {
    width: 150px;
    margin-left: 15px;
  }
  .el-table {
    .caret-wrapper {
      height: 23px;
      .ascending {
        top: 0px;
      }
      .descending {
        bottom: 1px;
      }
    }
  }
  .timeShow {
    .el-form-item__label {
      color: #666;
    }
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
